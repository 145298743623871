.cookieButton {
  border: 0px;
  background: rgb(140, 189, 63);
  color: rgb(255, 255, 255);
  padding: 4px 6px;
  margin-left: 12px;
}
.cookieContainer {
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  background: rgb(11, 65, 49);
  color: rgb(255, 255, 255);
}
.socialBar {
  background-color: #8cbd3f;
  color: #fff;
  height: 42px;
  display: flex;
  align-items: center;
  .container {
    &.container {
      @media (min-width: 320px) and (max-width: 767px) {
        width: 100% !important;
      }
    }
  }
  a {
    color: inherit;
    svg {
      width: 18px;
      height: 18px;
    }
    &:hover {
      color: rgb(11, 65, 49);
    }
  }
  .loginLink {
    display: flex;
    margin-right: 5px;
    p {
      margin-left: 12px;
      font-size: 16px;
      text-transform: uppercase;
    }
  }
  .profileLink {
    margin-right: 52px;
    @media (min-width: 320px) and (max-width: 767px) {
      margin-right: 0;
      margin-left: 24px;
    }
    .online {
      &::before {
        content: "";
        display: block;
        background: rgb(11, 65, 49);
        border-radius: 50%;
        box-shadow: 0 0 0 0 rgba(11, 65, 49, 1);
        height: 10px;
        width: 10px;
        transform: scale(1);
        animation: pulse-white 2.5s infinite;
        position: absolute;
        left: -24px;
        top: calc(50% - 4px);
      }
    }
  }
  .socialContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media (min-width: 320px) and (max-width: 767px) {
      justify-content: space-between;
    }
  }
  .socialLinks {
    a {
      padding: 2px 8px;
      display: inline-flex;
    }
  }
}
@keyframes pulse-white {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(11, 65, 49, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(11, 65, 49, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(11, 65, 49, 0);
  }
}
